<template>
    <div id="NoticeDetail">
        <div class="container">
            <div class="board_tit_box underline mb-4 d-flex align-items-center">
                <h4 class="mb-0 mr-2 main_txt">Q</h4>
                <p class="tit font-weight-bold pr-1">{{info.title}}</p>
            </div>
            <pre class="board_content_box" v-html="sanitizeHtml(info.content)">
            </pre>
        </div>
    </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';

export default {
    data(){
        return{
            title: 'FAQ',
            prevP: false,
            pageD: false,
            index: this.$route.params.index,
            info: {}
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    methods: { 
        sanitizeHtml : sanitizeHtml,
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                const code = res.data.code;
                if(code == '200'){         
                    this.noticeDetail();
                }else if(code == '9999'){
                    this.$alert(this.$t('signout.1')).then(()=>{
                        this.$store.dispatch('SETLOGOUT').then(()=>{
                            this.$router.push({name:'Signin'});
                        });
                    })
                }
                }
            })
        },
        noticeDetail: function() {
            const api = this.$store.state.baseURL;
            const no = this.index;
            
            this.$http.post(`${api}/member/board/faq/detail`,{no}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            this.info = res.data.info;
                        }
                    }
                }
            )
        }
    },
}
</script>
<style scoped>
    .board_tit_box .tit{
        white-space: initial;
    }
</style>