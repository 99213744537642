<template>
    <div id="NoticeList">
        <div class="container">
                <ul class="board_tit_box" v-if="list.length > 0">
                    <li v-for="(item,index) in list" :key="index"> 
                        <router-link :to="`/${$i18n.locale}/page/faq/${item.no}`" class="flex-wrap">
                            <div class="w-100 d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <h4 class="mb-0 mr-2 main_txt">Q</h4>
                                    <p class="tit font-weight-bold pr-1">{{item.title}}</p>
                                </div>
                                <!-- <i class="far fas fa-chevron-right"></i> -->
                            </div>
                            <small class="pale_txt answer">
                                {{item.content}}
                            </small>
                        </router-link>
                    </li>
                </ul>
            <div class="empty_box" v-if='emptyCHK'>
                <i class="fal fa-comment-alt-lines"></i>
                <p class="tit">{{$t("notice.2")}}</p>
            </div>
        </div>    
    </div>
</template>

<script>

export default {
    data(){
        return{
            lang: this.$route.params.lang,
            title: 'FAQ',
            prevP: false,
            pageD: false,
            
            list: [],
            emptyCHK: false
        }
    },
    created() {        
        const login = this.$store.state.login;
        if(login){
            this.Token_Check();
        }
    },
    mounted(){
        this.$store.commit("chgTitle",this.title)
        this.$store.commit("backPage",this.prevP)
        this.$store.commit("backDashboard",this.pageD)
    },
    methods: {
        Token_Check: function(){
            const api = this.$store.state.baseURL;
            this.$http.post(`${api}/member/wallet/token/check`, {}).then(
            res => {
                if (res.status == 200) {
                    const code = res.data.code;
                    if(code == '200'){    
                        this.noticeList();
                    }else if(code == '9999'){
                        this.$alert(this.$t('signout.1')).then(()=>{
                            this.$store.dispatch('SETLOGOUT').then(()=>{
                                this.$router.push({name:'Signin'});
                            });
                        })
                    }
                }
            })
        },
        noticeList: function() {
            const api = this.$store.state.baseURL;
            const lang = this.lang;
            
            this.$http.post(`${api}/member/board/faq/list`,{lang}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){                            
                            this.list = res.data.list;
                            if(res.data.list.length == 0) this.emptyCHK = true;
                            else this.emptyCHK = false;
                        }
                    }
                }
            )
        }
    },
}
</script>
<style scoped>
    .container{
        min-height: calc(100vh - 50px);
        padding-top: 35px;
    }
    ul{
        padding-left: 0;
    }
    .badge{
        border-radius: 0 !important; 
    }
    .board_tit_box .tit{
        white-space: initial;
    }
    .answer{
        display:-webkit-box;
        -webkit-line-clamp:2;
        -webkit-box-orient:vertical;
        white-space:normal;
        line-height: 20px;
        max-height: 40px;
        overflow:hidden;
        text-overflow:ellipsis;

        /* padding-left: 10px; */
        margin-top: 8px;
        font-size: 0.8em;
    }
</style>
